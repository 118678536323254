<template>
  <div>
    <!-- <div class="title">Shelves Management</div>
    <el-button type="primary" @click="() => addparent(data)" class="addparent">
      Add+
    </el-button>
    <el-tree
      :data="data"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
        <span class="btn">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="() => append(data)"
          >
            Append
          </el-button>
          <el-button type="info" plain size="mini" @click="() => edit(data)">
            Edit
          </el-button>
          <el-button
            type="danger"
            size="mini"
            plain
            @click="() => remove(data)"
          >
            Delete
          </el-button>
          <a :href="data.barcode" target="_blank">
            <span class="iconfont icon-tiaoxingma"></span
          ></a>
        </span>
      </span>
    </el-tree>
    <el-dialog title="Append" :visible.sync="dialogVisible" width="50%">
      <div>
        <el-form
          :model="Adddata"
          status-icon
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="Name" prop="label">
            <el-input
              type="text"
              v-model="Adddata.label"
              autocomplete="off"
              style="width: 500px"
            ></el-input>
          </el-form-item>
          <el-form-item label="Sort" prop="sort">
            <el-input
              type="text"
              v-model="Adddata.sort"
              autocomplete="off"
              style="width: 500px"
              placeholder="The sort must betwent 0 to 255 digits.Smaller more front."
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">Cancel</el-button>
        <el-button type="primary" size="mini" @click="sureadd">OK</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit" :visible.sync="VisiblEdeit" width="50%">
      <div>
        <el-form :model="Editdata" label-width="100px">
          <el-form-item label="Name" prop="label">
            <el-input
              type="text"
              v-model="Editdata.label"
              autocomplete="off"
              style="width: 500px"
            ></el-input>
          </el-form-item>
          <el-form-item label="Sort" prop="sort">
            <el-input
              type="text"
              v-model="Editdata.sort"
              autocomplete="off"
              style="width: 500px"
              placeholder="The sort must betwent 0 to 255 digits.Smaller more front."
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="VisiblEdeit = false" size="mini">Cancel</el-button>
        <el-button type="primary" size="mini" @click="sureEdit">OK</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
// import {
//   shelveslist,
//   shelvesadd,
//   shelvesmove,
//   shelvesedit
// } from '../../api/user'
// let id = 1000
export default {
  data() {
    return {
      // data: [],
      // Adddata: {
      //   label: '',
      //   sort: ''
      // },
      // Editdata: {
      //   label: '',
      //   sort: ''
      // },
      // dialogVisible: false, // 添加弹框显示隐藏
      // VisiblEdeit: false, // 修改弹框显示隐藏
      // addmsg: '',
      // addparent_id: '',
      // edit_id: ''
    }
  },
  // created() {
  //   this.getshelveslist()
  // },
  methods: {
    // 渲染
    // async getshelveslist() {
    //   const data = await shelveslist()
    //   console.log(data)
    //   this.data = data
    // },
    // 打开父添加模态框
    // addparent() {
    //   this.dialogVisible = true
    //   this.addparent_id = 0
    //   this.Adddata = {}
    // },
    // 打开添加模态框
    // append(data) {
    //   // console.log(data)
    //   this.dialogVisible = true
    //   this.Adddata = {}
    //   this.addparent_id = data.id
    // },
    // 打开修改模态框
    // edit(data) {
    //   this.VisiblEdeit = true
    //   // console.log(data)
    //   this.edit_id = data.id
    //   // 回填
    //   this.Editdata.label = data.label
    //   this.Editdata.sort = data.sort
    // },
    // 删除
    // remove(data) {
    //   this.$confirm(
    //     'This operation will be permanently deleted. Do you want to continue?',
    //     'Tips',
    //     {
    //       confirmButtonText: 'OK',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }
    //   )
    //     .then(async () => {
    //       const msg = await shelvesmove({
    //         id: data.id
    //       })
    //       if (msg.status === 200) {
    //         this.$message({
    //           message: 'Delete successfully',
    //           type: 'success'
    //         })
    //         this.getshelveslist()
    //       } else {
    //         this.$message({
    //           message: msg.data.toperr,
    //           type: 'error'
    //         })
    //       }
    //     })
    //     .catch(() => {
    //       // this.$message({
    //       //   type: 'info',
    //       //   message: 'Canceled delete.'
    //       // })
    //     })
    // },
    // 添加接口
    // async sureadd() {
    //   const data = await shelvesadd({
    //     parent_id: this.addparent_id,
    //     name: this.Adddata.label,
    //     sort: this.Adddata.sort
    //   })
    //   // console.log(data)
    //   if (data.status === 200) {
    //     this.getshelveslist()
    //     this.dialogVisible = false
    //   } else {
    //     this.$message({
    //       message: data.data.toperr,
    //       type: 'error'
    //     })
    //   }
    // }
    // 修改接口
    // async sureEdit() {
    //   const data = await shelvesedit({
    //     id: this.edit_id,
    //     name: this.Editdata.label,
    //     sort: this.Editdata.sort
    //   })
    //   // console.log(data)
    //   if (data.status === 200) {
    //     this.getshelveslist()
    //     this.VisiblEdeit = false
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
// .custom-tree-node {
//   flex: 1;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   font-size: 16px;
//   padding-right: 8px;
// }
// .icon-tiaoxingma {
//   padding-left: 100px;
//   font-size: 24px;
//   color: #909399;
// }
// .title {
//   font-size: 30px;
//   font-weight: bold;
//   padding-bottom: 40px;
// }
// /deep/.el-tree-node__content {
//   height: 35px;
//   border-bottom: 1px solid #ebeef5;
//   padding: 5px 0 5px 18px;
// }
// .addparent {
//   margin-bottom: 20px;
// }
// .btn a {
//   text-decoration: none;
// }
// /deep/.el-input {
//   width: 100% !important;
// }
</style>
